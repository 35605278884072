.financesHeader {
  background-color: #fff;
  padding-bottom: 20px;
  border-radius: 8px;
}
.showAllSpan {
  color: #333;
  font-size: 0.9rem;
}
.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
