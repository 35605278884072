/* for chrome */
.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.hideScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.lavUay {
  margin: 0px !important;
}
.sidebar-menu-item :hover {
  background-color: rgba(14, 75, 116, 0.4) !important;
}
.item-content:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.left-col {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
}

.center-col {
  flex: 1;
  background: #aaa;
  overflow-y: scroll;
}

.right-col {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7e7;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.side-drawer {
  /* padding-top: 4%; */
  background-color: #049eff;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: scroll;
  transform: translateX(-5%);
  transition: transform 0.3s ease-out;
  min-width: 100px;
}

.open {
  min-width: 280px;
  /* padding-top: 4%; */
  background-color: #049eff;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: scroll;
  transform: translateX(0);
  transition: transform 0.3s ease-out;
}
.open ul a:hover {
  background-color: rgba(14, 75, 116, 0.4) !important;
}
/* .side-drawer {
  padding-top: 4%;
  height: 100vh;
  background-color: #049eff;
  box-shadow: "0 4px 16px 0 rgba(0,0,0,0.3)";
  position: sticky;
  min-width: 80px;
  top: 0;
  overflow: scroll;
  transition: all 0.1s ease-in-out;
}
.open {
  min-width: 220px;
  height: 100vh;
} */

@media screen and (max-width: 900px) {
  .side-drawer {
    min-width: 100px !important;
  }
}

@media screen and (max-width: 900px) {
  .side-drawer {
    min-width: 100px !important;
    padding-top: 10%;
  }
}
@media screen and (min-width: 902px) and (max-width: 1199) {
  .side-drawer {
    padding-top: 20%;
  }
}
