.bg-light {
  background-color: #fff !important;
  /* padding-top: "2%";
  padding-bottom: "2%"; */
  /* height: 60px !important; */
}
.modal-content span {
  color: red;
}
.switchClass {
  color: red;
}
.switchClass:hover {
  color: rgb(167, 81, 81);
}

@media screen and (max-width: 700px) {
  .navbarIcons {
    padding-left: 50px !important;
  }
}
.close {
  display: none;
}
#dropdown-basic::after {
  display: none;
}
.dropdownMenu {
  padding-left: 20px;
  width: 500px;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  right: -110px;
  top: 55px;
}
.emptyDropdownMenu {
  padding-left: 20px;
  right: -70px;
  top: 55px;
}
.navLink {
  color: #333;
  font-weight: bold;
}
.modal-open {
  padding-right: 0px !important;
}
