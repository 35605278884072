.addAttrContainer {
  background-color: #f0f0f0;
}
.nameInp {
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  margin-right: 10px;
  padding-left: 10px;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.disabledInp {
  height: 36px;
  border-radius: 4px;
  border: 1px solid #b3b3b3;
}
.numberInp {
  width: 10.7em;
  padding-left: 8px;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
}
