.cusName {
  font-family: Sen;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  // color: #1a1a1a;
  color: "red";
}
