.LoginRootContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formContainer {
  border-radius: 16px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
  padding-bottom: 3%;
}

.loginBtn {
  background-color: #049eff;
  border: none;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
}

.forgotTxt {
  font-size: 14px;
  cursor: pointer;
  margin-top: 0.4rem;
  color: #049eff;
  text-decoration: underline;
}

.formInp {
  border: 1px solid rgb(197, 197, 197);
  border-radius: 4px;
  height: 2.4rem;
  padding-left: 1rem;
}

.inpLabel {
  color: gray;
}

.formErr {
  color: red;
  font-size: 14px;
  text-align: start;
  margin-top: 5px;
}

.copyrightTxt {
  margin-top: 4%;
  color: #333;
  font-size: 13px;
}
