body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.page-item.active .page-link {
  background-color: #049eff !important;
  color: "#000" !important;
  border-color: #049eff !important;
}
.bg-light {
  background-color: #fff !important;
}
/* For Model Color */

/* For Items Text Size and Color */
