.statsCard {
  width: 24%;
  background-color: #fff;
  border: 1px solid rgb(216, 216, 216);
  position: relative;
  border-radius: 4px;
  min-height: 150px;
  padding: 15px;
  float: left;
  margin-right: 5px;
  box-sizing: border-box;
}
.myh5 {
  margin-bottom: 0px;
  color: #6c757d;
  font-size: 1.2rem;
}

@media (max-width: 1024px) {
  .statsCard {
    width: 45%;
    min-height: 120px;
  }
}

@media (max-width: 562px) {
  .statsCard {
    width: 80%;
    // min-height: 120px;
    margin-bottom: 5px;
  }
}
